<template>
  <div  class="ma-md-5 mb-16 px-0 " >

    <div class="d-flex">
      <v-btn icon small class="px-4 mt-6" to="/profile/menu" v-if="$vuetify.breakpoint.mobile">
        <v-icon size=20>mdi-arrow-left</v-icon>
      </v-btn>

      <div class="text-h6 font-weight-bold my-6">心愿单</div>
    </div>

    <div class="text-h7 ">共件{{count}}商品</div>

    <v-item-group active-class="primary">
      <v-row>
        <v-col v-for="goods in list" :key="goods.goods_id" cols="6" md="4">
          <v-item >
            
              <v-card
                elevation="0"
                   
                class="align-center goods-item"
                @click="$router.push('/itemdetail?id='+goods.goods_id+'')"
              >
           
                   <v-btn icon small class="favorite-icon mt-6 mr-3" fab absolute top right @click.stop="removeFavorite(goods.goods_id)" >
                    <v-icon size=20>mdi-cards-heart</v-icon>
                  </v-btn>

     
                <v-img
                  :src="domain+goods.pic_cover_mid"
                  aspect-ratio="0.87"
                  max-height=330
                  class=""
                >
                </v-img>
                <div class="font-weight-black text-center  mb-2 mt-4">
                  {{goods.brand_name}}
                </div>
                <div class="font-weight-regular text-center  text-caption text-md-subtitle-1 mb-2">
                  {{goods.goods_name}}
                </div>
                <div class="font-weight-regular text-center  text-body-2 my-2">
                  ¥{{goods.price}}
                </div>
              </v-card>
            
          </v-item>
        </v-col>
      </v-row>
      </v-item-group>

  </div>
</template>

<script>


import goodsApi from '@/api/goods'
import { mapState } from 'vuex'



export default {


  data: () => ({

    list : [],
    

  
    //
  }),
  computed: {
    ...mapState({
      domain: state => state.app.imgdomain,
    }),
    count : function () {
      return this.list.length
    }
  },
  created() {

    goodsApi.favorite().then(res => {
      this.list = res.data.data
    })
    
  },
  methods: {
    homesearch() {
      alert(3)
    },
    removeFavorite(id) {
      let params = {id:id}
      goodsApi.removefavorite(params).then(() => {
        goodsApi.favorite().then(res => {
          this.list = res.data.data
        })
      })
    }
    
  },
};
</script>
<style scoped>
.content {
  
  position: relative;
}  
.goods-item {
  cursor: pointer;
}



</style>

<style lang="sass">
  @import '~vuetify/src/styles/styles.sass'

  @media #{map-get($display-breakpoints, 'md-and-up')}
    .categorybanner .v-image__image--cover
      background-position : center

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    .categorybanner .v-image__image--cover
      background-position : 17% !important
  
  .v-card.on-hover.theme--dark
    background-color: rgba(#FFF, 0.8)
    >.v-card__text
      color: #000000

</style>